import { FormHelperText, Label } from '@library/forms';
import { authActions } from '@store/auth';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ErrorMessage, Form, Formik } from 'formik';
// import jwtDecode from 'jwt-decode';
import { IAuth } from '@api/models';
import { Button } from '@hyperflake/react-ui-library';
import { getInitials } from '@library/avatar/helpers/avatar.utils';
import { CircularProgress } from '@library/loaders/components';
import { FormikInput } from 'modules/shared/features/formik';
import useAuthSelector from 'modules/shared/hooks/use-auth-selector';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const loginFormSchema = Yup.object({
    emailOrUsername: Yup.string().required('This field is required.'),
    password: Yup.string().required('This field is required.'),
});

interface ILogin {
    emailOrUsername: string;
    password: string;
}

const initialValues: ILogin = {
    emailOrUsername: '',
    password: '',
};

const Login = () => {
    const navigate = useNavigate();

    const { token } = useAuthSelector();
    const dispatch = useDispatch();

    const [hasMultiAccount, setHasMultiAccount] = useState(false);

    useEffect(() => {
        if (token) {
            navigate('/', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loginMutation = useMutation(async (payload: ILogin) => {
        const { data } = await axios.post(`${import.meta.env.VITE_APP_AUTH_URL}/login`, payload);

        return data as IAuth[];
    });

    const handleSubmit = async (values: ILogin) => {
        if (!values.emailOrUsername || !values.password) {
            toast.error('Invalid Credentials.');
            return;
        }
        try {
            const res = await loginMutation.mutateAsync(values);

            if (res.length > 1) {
                setHasMultiAccount(true);
            } else {
                dispatch(authActions.login({ token: res[0].token, rememberMe: true }));
                navigate('/', { replace: true });
            }

            // when use will also be returned
            // const payload: Partial<IAgencyUser> = jwtDecode(token);
            // const currentUser = { _id: payload._id, name: payload.name, agency: payload.agency };
            // dispatch(authActions.login({ token: token, user: currentUser, rememberMe: true }));

            // dispatch(authActions.login({ token: token, rememberMe: true }));

            // navigate('/', { replace: true });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            toast.error(err.response.data.message);
        }
    };

    const handleLoginToAccount = (account: IAuth) => {
        dispatch(authActions.login({ token: account.token, rememberMe: true }));
        setHasMultiAccount(false);
        navigate('/', { replace: true });
    };

    return (
        <>
            {!hasMultiAccount && (
                <div className="w-full min-h-screen  flex bg-[#720e0c]">
                    <div className="card rounded-2xl px-9 py-8 w-full max-w-[580px] m-auto">
                        <img className="h-6" src="/logos/logo-light.svg" />

                        <div className="mt-6 bg-white px-10 py-10 rounded-lg">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={loginFormSchema}
                            >
                                <Form>
                                    <div>
                                        <Label htmlFor="emailOrUsername">Email or Username</Label>
                                        <FormikInput
                                            type="text"
                                            name="emailOrUsername"
                                            placeholder="Enter your email or username"
                                        />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="emailOrUsername" />
                                        </FormHelperText>
                                    </div>

                                    <div>
                                        <Label htmlFor="password">Password</Label>
                                        <FormikInput
                                            type="password"
                                            name="password"
                                            placeholder="Enter your password"
                                        />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="password" />
                                        </FormHelperText>
                                    </div>

                                    <Button
                                        type="submit"
                                        className="mt-4 w-full"
                                        size={'xl'}
                                        disabled={loginMutation.isLoading}
                                    >
                                        {loginMutation.isLoading ? (
                                            <CircularProgress className="text-white" size={24} />
                                        ) : (
                                            'Login'
                                        )}
                                    </Button>

                                    {/* <div className="mt-12 text-sm">
                                <span className="text-gray-500 mr-2">Not on Farepod yet?</span>
                                <a className="text-blue-600 font-semibold underline cursor-pointer">Sign Up</a>
                            </div> */}
                                </Form>
                            </Formik>
                        </div>
                        <div className="mt-10 !text-white text-center">
                            Copyright ©️ 2024 TimeLapse ME · Privacy & terms
                        </div>
                    </div>
                </div>
            )}

            {hasMultiAccount && (
                <div className="w-full min-h-screen  flex bg-[#720e0c]">
                    <div className="card rounded-2xl px-9 py-8 w-full max-w-[580px] m-auto">
                        <img className="h-6" src="/logos/logo-light.svg" />

                        <div className="mt-6 bg-white px-10 py-10 rounded-lg">
                            <h3 className="font-semibold">Choose account</h3>
                            <div className="font-light mb-5">Multiple clients found under your email.</div>
                            <div className="space-y-2">
                                {loginMutation.data?.map((row) => (
                                    <div
                                        key={row._id}
                                        className="py-2 cursor-pointer hover:bg-gray-50"
                                        onClick={() => handleLoginToAccount(row as IAuth)}
                                    >
                                        <div className="select-none flex-1 overflow-hidden">
                                            <div className="flex items-center gap-2">
                                                <div className="flex items-center justify-center rounded-full bg-gray-500 size-9 text-white">
                                                    {getInitials(row.reseller.name)}
                                                </div>
                                                <div>
                                                    <div className="font-semibold text-sm">{row.reseller.name}</div>
                                                    <div className="font-medium text-gray-500 text-xs">
                                                        {row.username}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <button
                                    className="text-sm w-full text-center mx-auto text-gray-500 font-medium mt-4"
                                    onClick={() => navigate('/', { replace: true })}
                                >
                                    Back to login
                                </button>
                            </div>
                        </div>
                        <div className="mt-10 text-white text-center">
                            Copyright ©️ 2024 TimeLapse ME · Privacy & terms
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Login;
