import { ICamera } from '@api/models/camera.model';
import { CircularProgress } from '@library/loaders/components';
import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
// import CameraCard from '../CameraCard/CameraCard';
// import useResellerCameraListData from 'modules/reseller-cameras/hooks/useResellerCameraListData';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { Link, useNavigate } from 'react-router-dom';
import CameraCard from '../CameraCard/CameraCard';
import { InfoCircleIcon } from '@icon/index';
import { Tooltip } from '@library/tooltip';
import useResellerCameraListData from 'modules/reseller-cameras/hooks/useResellerCameraListData';
import { SearchBar } from 'modules/shared/features/search';
import { IClient, IProject } from '@api/models';

interface IPaginatedCameres {
    cameras: ICamera[];
    pageCount: number;
}

const ResellerCameraList = () => {
    const navigate = useNavigate();

    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    const topBarRef = useRef<HTMLDivElement>(null);

    const { data, isLoading, searchValue, setSearchQuery, filteredCamera } = useResellerCameraListData();

    // const { isLoading, data, isFetching, fetchNextPage } = useInfiniteQuery<IPaginatedCameres>(
    //     ['reseller', 'all cameras'],
    //     async ({ pageParam = 1 }) => {
    //         const { data } = await http.get(
    //             `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/cameras?size=${size}&page=${pageParam}`
    //         );
    //         return data;
    //     },
    //     {
    //         getNextPageParam: (lastPage, pages) => {
    //             return pages.length < lastPage.pageCount ? pages.length + 1 : undefined;
    //         },
    //     }
    // );

    // useEffect(() => {
    //     console.log('running', inView);
    //     if (inView) {
    //         fetchNextPage();
    //     }
    // }, [inView]);

    // const cameras = useMemo(() => {
    //     if (isLoading) return [];

    //     if (data) {
    //         let mergedData: ICamera[] = [];
    //         const { pages } = data;
    //         pages.forEach((pagedCamera: IPaginatedCameres) => {
    //             mergedData = [...mergedData, ...pagedCamera.cameras];
    //         });
    //         return mergedData;
    //     }
    //     return [];
    // }, [data, isLoading, isFetching]);

    return (
        <PageTemplate ref={topBarRef}>
            {/* //     <Breadcrumb separator='/' items={navArray} /> */}

            <PageHeading>
                {!isLoading && filteredCamera && (
                    <div className="flex items-center gap-x-3">
                        <span> Active Cameras - ({data?.count} )</span>
                        <Tooltip
                            content={
                                'Cameras which have uploaded to the ProgressCenter server in the past 9 Days will be considered active.'
                            }
                            placement="bottom-end"
                        >
                            <span className="text-grayscale-500">
                                <InfoCircleIcon />
                            </span>
                        </Tooltip>
                    </div>
                )}
            </PageHeading>
            {/* <PageHeading>Reseller Camera</PageHeading> */}
            {/* <div className="mt-6">
                <SearchBar value={searchValue} onChange={setSearchQuery} />
            </div> */}

            <div
                className="pl-4"
                style={{
                    height: `${window.innerHeight - (+topBarRef?.current?.offsetHeight || 0)}px`,
                }}
            >
                <div className="mt-4 ">
                    {isLoading ? (
                        // <div className="grid grid-cols-4 2xl:grid-cols-4 gap-6">
                        //     {Array(20)
                        //         .fill(1)
                        //         .map((_, index) => (
                        //             <CameraCardSkelton unique={index.toString()} key={index} />
                        //         ))}
                        // </div>
                        <div className="flex justify-center py-36">
                            <CircularProgress />
                        </div>
                    ) : (
                        // <div className="grid grid-cols-4 2xl:grid-cols-5 gap-6">
                        //     {cameras.map((camera, index, allCameras) => {
                        //         return (
                        //             <CameraCard
                        //                 camera={camera}
                        //                 unique={index.toString()}
                        //                 isActivator={allCameras.length - 30 === index}
                        //                 ref={ref}
                        //                 key={camera._id}
                        //             />
                        //         );
                        //     })}
                        // </div>

                        <div className="grid grid-cols-4 gap-6 mt-6 empty:hidden">
                            {filteredCamera?.map((camera, index) => (
                                <div className="col-span-1" key={camera._id}>
                                    <Link
                                        to={`/clients/${(camera?.client as IClient)?._id}/projects/${
                                            (camera?.project as IProject)?._id
                                        }/cameras/${camera._id}`}
                                    >
                                        <CameraCard
                                            camera={camera}
                                            // onClick={() => navigate(`/cameras/${camera._id}`)}
                                            isActivator={filteredCamera.length - 30 === index}
                                            ref={ref}
                                        />
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}

                    {!filteredCamera && !isLoading && (
                        <div className="flex justify-center items-center pt-36 text-lg h-full w-full text-grayscale-500">
                            No Camera's Found
                        </div>
                    )}

                    {/* {isLoading && (
                        <div className="flex justify-center py-36">
                            <CircularProgress />
                        </div>
                    )} */}
                </div>
            </div>
        </PageTemplate>
    );
};

export default ResellerCameraList;
